import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import functions from "../../functions/functions";
import VariantsPopUp from "../VariantsPopUp";
import PlusIcon from "../../icons/PlusIcon";

function Products() {
	const { category, add_product, setPopup, mode, categoryPos, filteredCategories, setConfirm } =
		useContext(AppContext);
	const [filteredProducts, setfilteredProducts] = useState([]);
	const [src, setSrc] = useState("");
	const [srcProducts, setSrcProducts] = useState([]);

	useEffect(() => {
		if (filteredCategories && categoryPos) assignProductsToPosCategories();
	}, [filteredCategories, categoryPos]);

	useEffect(() => {
		filter();
	}, [src]);

	function handleClick(product) {
		console.log("handleClick", product.stock);
		if (
			(product.stock.product !== false && product.stock.product == 0) ||
			checkIngredientsStock(product.stock.ingredients)
		) {
			setConfirm({
				title: "Prodotto esaurito",
			});
		} else if (product.variants_categories.length === 0 && !hasRemovbleIngredients(product))
			add_product(product);
		else
			setPopup({
				title: product.name,
				fullContent: <VariantsPopUp product={product} />,
			});
	}

	function checkIngredientsStock(ingredients) {
		for (const i of ingredients) {
			if (i.qty == 0) return true;
		}
		return false;
	}

	function hasRemovbleIngredients(product) {
		for (const ingredient of product.ingredients) {
			if (ingredient.removable == 1) return true;
		}
		return false;
	}

	function assignProductsToPosCategories() {
		console.log("assignProductsToPosCategories");
		let arr = [];
		for (const p of categoryPos.products) {
			filteredCategories.map((course) =>
				course.categories.map((category) =>
					category.products.map((product) => {
						if (product.id == p.id) arr.push(product);
					})
				)
			);
		}
		arr.sort(function (a, b) {
			return a["name"] > b["name"] ? 1 : -1;
		});
		setfilteredProducts(arr);
	}

	function filter() {
		console.log("filter");
		let arr = [];
		if (src) {
			filteredCategories.map((course) =>
				course.categories.map((category) =>
					category.products.map((product) => {
						console.log(product.name, src, product.name.includes(src));
						if (product.name.toLowerCase().includes(src.toLowerCase()))
							arr.push(product);
					})
				)
			);
		}
		arr.sort(function (a, b) {
			return a["name"] > b["name"] ? 1 : -1;
		});
		setSrcProducts(arr);
	}

	return (
		<div className="products">
			<div className="src">
				<input
					type="text"
					placeholder="Ricerca"
					value={src}
					onChange={(e) => setSrc(e.target.value)}
				/>
				{src && <button onClick={() => setSrc("")}>x</button>}
			</div>
			{src && (
				<>
					<h3>Risultati della ricerca</h3>
					<div className="scroll">
						<div className="grid">
							{srcProducts.map((prod, n) => (
								<Product key={n} prod={prod} onClick={() => handleClick(prod)} />
							))}
						</div>
					</div>
				</>
			)}
			{!src && mode == 1 && (
				<>
					<h3>{category.name}</h3>
					<div className="scroll">
						<div className="grid">
							{category.products &&
								category.products.map((prod, n) => (
									<Product
										key={n}
										prod={prod}
										onClick={() => handleClick(prod)}
									/>
								))}
						</div>
					</div>
				</>
			)}
			{!src && mode == 2 && (
				<>
					<h3>{categoryPos.name}</h3>
					<div className="scroll">
						<div className="grid">
							{filteredProducts.map((prod, n) => (
								<Product key={n} prod={prod} onClick={() => handleClick(prod)} />
							))}
						</div>
					</div>
				</>
			)}
		</div>
	);
}

function Product({ prod, onClick }) {
	const { config } = useContext(AppContext);
	return (
		<button onClick={onClick} className={config.product_button_height}>
			<div className="product-info">
				<div className="product-name">{prod.name && prod.name.replaceAll("_", " ")}</div>
				<div className="stock">
					{prod.stock.product !== false && <div>Restanti: {prod.stock.product}</div>}
					{prod.stock.ingredients.map((ingredient, i) => (
						<div key={i}>
							{ingredient.name}: {ingredient.qty}
						</div>
					))}
				</div>
				<div className="product-price">{functions.formatter.format(prod.price)}</div>
			</div>
			<div className="product-color" style={{ backgroundColor: prod.color }}></div>
		</button>
	);
}

export default Products;
